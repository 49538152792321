<template>
  <div>
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>

    <v-dialog
      v-model="successDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-center">
          <v-col class="text-center pa-0">
            Quest {{ successDialogType }}
          </v-col>
        </v-card-title>
        <v-card-text>
          <!-- eslint-disable vue/singleline-html-element-content-newline -->
          <span v-if="successDialogType == 'Saved' || successDialogType == 'Joined'">
            <div class="text-center mb-4">
              <router-link :to="{name: 'Logbook'}">
                <v-icon
                  size="200"
                  color="primary"
                >
                  $logbook
                </v-icon>
              </router-link>
            </div>
            The Quest {{ quest.name }} has been saved to your <router-link :to="{name: 'Logbook'}">Logbook</router-link><span v-if="successDialogType == 'Joined'"> and started</span>.
          </span>
          <span v-else-if="successDialogType == 'Started'">
            The Quest {{ quest.name }} has been activated.
          </span>
          <span v-else-if="successDialogType == 'Quit'">
            You have quit the Quest {{ quest.name }}.
          </span>
          <span v-else-if="successDialogType == 'Resume'">
            You have resumed the Quest {{ quest.name }}.
          </span>
          <!--eslint-enable-->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="successDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="offlineDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          Internet Connection Required
        </v-card-title>
        <v-card-text>
          An internet connection is required<span v-if="offlineAction"> to {{ offlineAction }} this Quest</span>.
        </v-card-text>
      </v-card>
    </v-dialog>

    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Quest"
    />

    <div v-if="!loading && quest">
      <QuestHeader
        :quest="quest"
        :details="questDetails"
      />

      <v-row>
        <v-col>
          <Share
            :title="quest.name"
            class="mr-4"
          />
          <Like
            :object-id="quest.id"
            app="quest"
            model="quest"
            class="mr-4"
          />
          <Flag
            :object-id="quest.id"
            app="quest"
            model="quest"
          />
        </v-col>
      </v-row>

      <div class="quest-meta mb-6">
        <v-divider v-if="quest.author" />
        <v-row v-if="quest.author">
          <v-col>
            Author
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'Author', params: { slug: quest.author.slug }}">
              {{ quest.author.name }}
            </router-link>
          </v-col>
        </v-row>
        <v-divider v-if="quest.story" />
        <v-row v-if="quest.story">
          <v-col>
            Story
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'Story', params: { slug: quest.story.slug }}">
              {{ quest.story.name }}
            </router-link>
          </v-col>
        </v-row>
        <v-divider v-if="quest.season" />
        <v-row v-if="quest.season">
          <v-col>
            Season
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'Season', params: { slug: quest.season.slug }}">
              {{ quest.season.name }}
            </router-link>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col>
            Date
          </v-col>
          <v-col class="text-right">
            {{ quest.created|formatDate }}
          </v-col>
        </v-row>
        <v-divider v-if="quest.skills.length" />
        <v-row v-if="quest.skills.length">
          <v-col>
            Skills
          </v-col>
          <v-col class="text-right">
            <span
              v-for="(skill, index) in quest.skills"
              :key="skill.id"
            >
              {{ skill.name }}<span v-if="index != (quest.skills.length - 1)">, </span>
            </span>
          </v-col>
        </v-row>
        <v-divider />
      </div>

      <QuestLocked
        :quest="quest"
        :locked="questLocked"
      />

      <v-card
        v-if="!questLocked && quest.arena_geo"
        class="mt-6 mb-6"
        @click.stop="mapDialog = true"
      >
        <v-img
          v-if="quest.arena_static_url"
          :src="quest.arena_static_url"
          max-height="200"
        />
        <v-img
          v-else
          src="@/assets/default_map.jpg"
          max-height="200"
        />
        <v-card-title>
          View Map
        </v-card-title>
      </v-card>

      <v-dialog
        v-model="mapDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn
              icon
              dark
              @click="mapDialog = false"
            >
              <v-icon>
                $close
              </v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ quest.name }}: Arena Map
            </v-toolbar-title>
          </v-toolbar>
          <QuestMap
            :quest="quest"
            :visible="mapDialog"
          />
        </v-card>
      </v-dialog>

      <div
        v-if="!questLocked"
        class="section"
      >
        <div class="section-title">
          Dispatch
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="quest.dispatch" />
        <!--eslint-enable-->
      </div>

      <div class="text-center mb-4">
        <v-btn
          v-if="canJoinQuest"
          color="primary"
          :disabled="submitting"
          @click="joinQuest"
        >
          Join Quest Now
        </v-btn>
      </div>
      <div class="text-center mb-4">
        <v-btn
          v-if="canSaveQuest"
          color="primary"
          :disabled="submitting"
          @click="saveQuest"
        >
          Save Quest for Later
        </v-btn>
      </div>

      <v-alert
        v-for="error in errorMessages"
        :key="error"
        type="error"
      >
        {{ error }}
      </v-alert>

      <div
        v-if="participants.length"
        class="section"
      >
        <div class="section-title">
          Logbook
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th />
              <th>
                Status
              </th>
              <th>
                Saved
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="participant in participants"
              :key="participant.id"
            >
              <td>
                <v-btn
                  :to="{ name: 'Participant', params: { id: participant.id }}"
                >
                  Load
                </v-btn>
              </td>
              <td>
                {{ participant.status }}
              </td>
              <td>
                {{ participant.created|formatDate }}
              </td>
              <td>
                <ProfileAvatarList :profile-list="participantProfileList(participant)" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import Flag from '@/components/Flag.vue'
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import QuestHeader from '@/components/QuestHeader.vue'
import QuestLocked from '@/components/QuestLocked.vue'
import QuestMap from '@/components/QuestMap.vue'
import Share from '@/components/Share.vue'
import ProfileAvatarList from '@/components/ProfileAvatarList.vue'
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { mapState } from 'vuex'
import { isQuestLocked } from '@/utils/quest'

export default {
  components: {
    Flag,
    Like,
    Loading,
    QuestHeader,
    QuestLocked,
    QuestMap,
    Share,
    ProfileAvatarList,
  },
  data: function() {
    return {
      alerts: [],
      completeDialog: false,
      errorMessages: null,
      loading: false,
      mapDialog: false,
      inviteDialog: false,
      offlineAction: null,
      offlineDialog: false,
      quest: null,
      questDetails: null,
      questLocked: null,
      startNow: false,
      submitting: false,
      successDialog: false,
      successDialogType: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    participants() {
      return this.$store.getters['participants/filterByQuestID'](this.quest.id)
    },
    canSaveQuest() {
      return !this.questLocked && this.participants.length == 0
    },
    canJoinQuest() {
      return this.canSaveQuest && this.quest.can_accept_activations
    },
    isHidden() {
      return this.questLocked && this.quest.hide_if_locked
    },
  },
  created: function () {
    this.$store.dispatch('quests/getAll').finally(() => this.getQuest())
  },
  methods: {
    participantProfileList(participant) {
      return this.$store.getters['participants/profilesForParticipant'](participant.id)
    },
    getQuest() {
      this.loading = true
      this.quest = this.$store.getters['quests/getBySlug'](this.$route.params.slug) || null
      if (!this.quest) {
        return this.$router.push('/not-found/')
      }
      this.updateLock()
      if (this.isHidden) {
        return this.$router.push('/not-found/')
      }
      this.getQuestDetails()
    },
    updateLock() {
      if (isQuestLocked(this.quest, this.profile)) {
        this.$store.dispatch('quests/addLock', this.quest.id)
        this.questLocked = true
      } else {
        this.$store.dispatch('quests/removeLock', this.quest.id)
        this.questLocked = false
      }
    },
    getQuestDetails() {
      return this.$store.dispatch('quests/getDetails', '/quest/quest/' + this.quest.id + '/details/')
        .finally(() => {
          this.questDetails = this.$store.getters['quests/getDetailsByID'](this.quest.id) || null
          this.loading = false
          if (!this.$online && !this.questDetails) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to download the details of this Quest.',
            })
          } else if (!this.questDetails) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to download Quest details.',
            })
          }
        })
    },
    joinQuest() {
      if (!this.$online) {
        this.offlineAction = 'join'
        return this.offlineDialog = true
      }
      this.startNow = true
      this.saveQuest()
    },
    saveQuest () {
      if (!this.$online) {
        this.offlineAction = 'save'
        return this.offlineDialog = true
      }
      this.submitting = true
      this.errorMessages = null
      const data = {
        quest: this.quest.id,
        profile: this.profile.id,
      }
      if (this.startNow) {
        data.start = this.startNow
      }
      return allianceAPI.post('/quest/participant/', data)
        .then(() => {
          if (this.startNow) {
            this.successDialogType = 'Joined'
          } else {
            this.successDialogType = 'Saved'
          }
          this.successDialog = true
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          // Add a more useful unique error.
          let uniqueIndex = this.errorMessages.indexOf('The fields Quest, profile must make a unique set.')
          if (uniqueIndex > -1) {
            this.errorMessages[uniqueIndex] = 'You have already saved this Quest.'
          }
          this.success = false
        })
        .finally(() => {
          this.$store.dispatch('participants/getAll')
          this.submitting = false
        })
    },
  },
}
</script>
